<script>
import Vue from 'vue'
import Firebase from 'firebase/app'
import 'firebase/firestore'
import Antd, { Spin } from 'ant-design-vue'
import 'ant-design-vue/dist/antd.css'
import { mapState } from 'vuex'
import { firestorePlugin } from 'vuefire'
import { init } from 'commandbar'

import TopBar from '../components/TopBar.vue'
import FormEditorView from '../components/form/FormEditorView.vue'
import IncludeFonts from '../boot-up/IncludeFonts.vue'

import '@/helpers/prototypes'
import detectLocalStorage from '@/helpers/detectLocalStorage'
import { loadGooglePlaces } from '@/boot-up/scriptLoading'
import '@/boot-up/initServices'

if (Firebase.apps.length === 0) {
  var firebaseConfig = {
    apiKey: process.env.VUE_APP_FIREBASE_API_KEY,
    authDomain: process.env.VUE_APP_FIREBASE_AUTH_DOMAIN,
    databaseURL: process.env.VUE_APP_FIREBASE_DATABASE_URL,
    projectId: process.env.VUE_APP_FIREBASE_PROJECT_ID,
    storageBucket: process.env.VUE_APP_FIREBASE_STORAGE_BUCKET,
    messagingSenderId: process.env.VUE_APP_FIREBASE_MESSENGER_SENDER_ID,
    appId: process.env.VUE_APP_FIREBASE_APP_ID,
    measurementId: process.env.VUE_APP_FIREBASE_MEASUREMENT_ID,
  }

  // Initialize Firebase
  Firebase.initializeApp(firebaseConfig)
}

Vue.config.productionTip = false
Vue.config.performance = (detectLocalStorage() && localStorage.SavvyDebugMode) || false
Vue.config.devtools = (detectLocalStorage() && localStorage.SavvyDebugMode) || false

Vue.use(firestorePlugin)
Vue.use(Antd)

export default {
  components: { FormEditorView, IncludeFonts },
  name: 'FlowCommentable',
  provide() {
    return {
      _isStandalone: () => true,
      _register: (key, fn) => {
        this.$set(this.registered, key, fn)

        const self = this
        return function() {
          if (self.registered[key] === fn) self.$set(self.registered, key, undefined)
        }
      },
      _getRegistered: key => this.registered[key],
    }
  },
  props: { id: String },
  data() {
    return {
      registered: {},
      flows: [],
    }
  },
  computed: {
    ...mapState(['pageHeader']),
  },
  mounted() {
    /* CommandBar */
    init('87984c40')
    loadGooglePlaces()
    Spin.setDefaultIndicator({
      indicator: () => {
        return <Spinner></Spinner>
      },
    })
  },
  render() {
    return (
      <div id="App" class="h-screen flex flex-col">
        <IncludeFonts flows={this.flows} />
        <TopBar noAuthComments page={this.pageHeader || { title: 'Home', icon: 'home' }} />
        <FormEditorView class="h-auto" noAuthComments insideEditor key={this.id} id={this.id} />
      </div>
    )
  },
}
</script>
<style lang="scss">
@import '@/styles/_variables.scss';
@import '@/styles/_ant_base.scss';
#commandbar-launcher {
  display: none;
}

#App {
  @extend .variables;
  @extend .base-to-extend;

  .ant-layout {
    height: 100vh;

    .ant-layout-header {
      position: fixed;
      width: 100%;
      z-index: 20;
      background: none;
      padding: 0;
      height: unset;
      line-height: inherit;
    }
    .ant-layout-content {
      position: relative;
      overflow: hidden;
      display: flex;
      flex-direction: column;

      > div:not(.full-width) {
        // width: 90%;
        // max-width: 1280px;
        margin: 0;
      }

      > div.full-width {
        margin: 0 auto;
        width: 100%;
      }

      > .main-view {
        flex: 1;

        > .ant-page-header {
          padding: 20px;

          .ant-page-header-heading-title {
            font-size: 22px;
            color: inherit;

            .Icon {
              --fa-primary-color: var(--color-savvy);
            }
          }
          .ant-page-header-heading-tags {
            margin: 5px;
          }
        }
      }
    }
  }
}
</style>
